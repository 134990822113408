import './request-info.scss';
import {useIntl} from "react-intl";
import {Field} from 'formik';
import {typeIdentification} from '../../../__mocks__/data';
import _ from 'lodash';
import {WLOCreditAPIService} from '../../../services';
import toast from "react-hot-toast";
import {useLoginContext} from '../../../hooks/useLoginContext';
import {useRequestContext} from '../../../hooks/useRequestContext';
import {ANIPersona, CygnusPersona} from '../../../context/RequestContext';
import {useState} from 'react';
import BarLoader from "react-spinners/BarLoader";

interface FormKnowYouProps {
    errors?: any,
    values?: any,
}

export function FormKnowYou(props: FormKnowYouProps) {
    const {user} = useLoginContext();
    const {request, setRequest} = useRequestContext();
    const [isLoadingInfo, setIsLoadingInfo] = useState(false);
    const {errors, values} = props;
    const intl = useIntl();
    
    const handleSearch = async () => {
        let consultaANI = false;
        setIsLoadingInfo(true);
        if (!_.isEmpty(values.Pws_Tip_Identificacion!) || !_.isEmpty(values.Pws_Identificacion!)) {
            if (values.Pws_Identificacion.length < 5) {
                toast.error('Debe indicar un Número de Identificación entre 5 y 10 caracteres');
                setIsLoadingInfo(false);
            } else {
                if( !isNaN(values.Pws_Identificacion)){

                    try {
                        const person = await WLOCreditAPIService.consultaSolicitudCygnus({
                            Pws_Tip_Identificacion: values.Pws_Tip_Identificacion!,
                            Pws_Identificacion: values.Pws_Identificacion!
                        });
                        if (!_.isEmpty(person.payload)) {
                            if (person.payload.result === "1") {
                                const pCygnus = person.payload.data.datos_persona.Datos_persona[0] as CygnusPersona;
                                values.Pws_Apellidos1=pCygnus.Pws_Apellidos1;
                                values.Pws_Apellidos2=pCygnus.Pws_Apellidos2;
                                values.Pws_Nombres=pCygnus.Pws_Nombres;
                                setRequest({...request, personCygnus: pCygnus, searchANI: false, errorConsulta: false});
                                localStorage.setItem('person', JSON.stringify(person.payload.data.datos_persona.Datos_persona[0]));
                                setIsLoadingInfo(false);
                            } else {
                                consultaANI = true;
                                const personANI = await WLOCreditAPIService.consultaSolicitudANI({
                                    Pws_Tip_Identificacion: values.Pws_Tip_Identificacion!,
                                    Pws_Identificacion: values.Pws_Identificacion!
                                });
                                const estadoCedula =  localStorage.getItem('estadoCedula')
                                if (estadoCedula != "0" &&
                                    estadoCedula != "1"   ) {
                                    console.log(person)
                                    toast.error('Su Solicitud es negada por inconsistencias en su cédula');


                                }else{
                                const pANI = personANI as ANIPersona;
                                const fecha = personANI.fechaExpedicion.split('/');
                                values.Pws_Apellidos1=pANI.primerApellido;
                                values.Pws_Apellidos2=pANI.segundoApellido;
                                values.Pws_Nombres=pANI.primerNombre+" "+pANI.segundoNombre;
                                values.Pws_Fec_expe= fecha[2]+'-'+fecha[1]+'-'+fecha[0]
                                pANI.fechaExpedicion = fecha[2]+'-'+fecha[1]+'-'+fecha[0]
                                setIsLoadingInfo(false);
                                setRequest({...request, personANI: pANI, searchANI: true, errorConsulta: false});
                                localStorage.setItem('person', JSON.stringify(person));
                            }
                        }
                        }else{
                            console.log(person)
                            toast.error('En estos momentos no podemos atender su solicitud');
                            
                        }
                    } catch (e) {
                        const error = e as any;
                        if (!_.isEmpty(error.response.data)) {
                            setRequest({...request, errorConsulta: true});
                            const menssage = error.response.data as any;
                            toast.error("El documento de identificación no existe.");
                            setTimeout(() => {
                                setIsLoadingInfo(false);
                            }, 100);
                    } else {
                        setRequest({...request, errorConsulta: true});
                        toast.error('En estos momentos no podemos atender su solicitud');
                        setTimeout(() => {
                            setIsLoadingInfo(false);
                        }, 100);
                        
                    }
                    }
                }else{
                    toast.error("La documento de identificacion debe ser numerico")
                }
            }
        }
    }

    return (
        <div className={'row'}>
            <div className={"title-medium-red"}>{intl.formatMessage({id: "form_credit_information_title"})}</div>
            <div className={"subtitle-medium"}>{intl.formatMessage({id: "form_credit_information_subtitle"})}</div>
            <div className={'flex-container-form-know-you'}>
                <div className={"flex-container-form-know-you-item-right flex-grow-6"}>
                    <Field as="select"
                           id={"Pws_Tip_Identificacion"}
                           name={"Pws_Tip_Identificacion"}
                           className={`${errors.Pws_Tip_Identificacion ? 'form-know-you-item-input-small input-error' : 'form-know-you-item-input-small'}`}>
                        <option>{intl.formatMessage({id: "placeholder_identification_type"})}</option>
                        {typeIdentification && typeIdentification.WS_TIPOS_IDENTIFICACION_IResult.LstTiposIdent.Datos_TiposIdentificacion.map((item, key: number) => {
                            return (
                                <option key={key} value={item.s_codigo}>{item.s_descripcion}</option>
                            )
                        })}
                    </Field>
                </div>
                <div className={"flex-container-form-know-you-item-left flex-grow-6"}>
                    <Field id={"Pws_Identificacion"}
                           onBlur={handleSearch}
                           name={"Pws_Identificacion"}
                           className={`${errors.Pws_Identificacion ? 'form-know-you-item-input-small input-error' : 'form-know-you-item-input-small'}`}
                           placeholder={intl.formatMessage({id: "placeholder_document_number"})}/>
                    <BarLoader height={4} width={300} color={"#d72222"} loading={isLoadingInfo}/>
                </div>
            </div>
            <div className={'flex-container-form-know-you'}>
                <div className={"flex-container-form-know-you-item-center flex-grow-6"}>
                    <Field id={"Pws_Nombres"}
                           name={"Pws_Nombres"}
                           disabled={isLoadingInfo}
                           className={`${errors.Pws_Nombres ? 'form-know-you-item-input-large input-error' : 'form-know-you-item-input-large'}`}
                           placeholder={"Nombres"}/>
                </div>
            </div>
                <div className={'flex-container-form-know-you'}>
                <div className={"flex-container-form-know-you-item-right flex-grow-6"}>
                    <Field id={"Pws_Apellidos1"}
                           name={"Pws_Apellidos1"}
                           disabled={isLoadingInfo}
                           className={`${errors.Pws_Apellidos1 ? 'form-know-you-item-input-small input-error' : 'form-know-you-item-input-small'}`}
                           placeholder={'Primer Apellido'}/>
                </div>
                <div className={"flex-container-form-know-you-item-left flex-grow-6"}>
                    <Field id={"Pws_Apellidos2"}
                           name={"Pws_Apellidos2"}
                           disabled={isLoadingInfo}
                           className={`${errors.Pws_Apellidos2 ? 'form-know-you-item-input-small input-error' : 'form-know-you-item-input-small'}`}
                           placeholder={'Segundo Apellido'}/>
                </div>
                </div>
              
            <div className={'flex-container-form-know-you'}>
                <div className={"flex-container-form-know-you-item-right flex-grow-6"}>
                    <Field id={"Pws_Telefono1"}
                           disabled={isLoadingInfo}
                           name={"Pws_Telefono1"}
                           className={`${errors.Pws_Telefono1 ? 'form-know-you-item-input-small input-error' : 'form-know-you-item-input-small'}`}
                           placeholder={intl.formatMessage({id: "placeholder_phone_1"})}/>
                </div>
                <div className={"flex-container-form-know-you-item-left flex-grow-6"}>
                    <Field id={"Pws_Correo"}
                           name={"Pws_Correo"}
                           disabled={isLoadingInfo}
                           className={`${errors.Pws_Correo ? 'form-know-you-item-input-small input-error' : 'form-know-you-item-input-small'}`}
                           placeholder={intl.formatMessage({id: "placeholder_mail"})}/>
                </div>
            </div>
            <div className={'flex-container-form-know-you'}>
                <div className={"flex-container-form-know-you-item-right flex-grow-6"}>
                    <Field id={"Pws_Telefono2"}
                           name={"Pws_Telefono2"}
                           disabled={isLoadingInfo}
                           className={`${errors.Pws_Telefono2 ? 'form-know-you-item-input-small input-error' : 'form-know-you-item-input-small'}`}
                           placeholder={intl.formatMessage({id: "placeholder_phone_2"})}/>
                </div>
                <div className={"flex-container-form-know-you-item-left flex-grow-6"}>
                    <Field id={"mail_confirmation"}
                     onPaste={(e:any)=>{
                        e.preventDefault()
                        return false;
                      }} 
                       autoComplete="off"
                       disabled={isLoadingInfo}
                           name={"mail_confirmation"}
                           className={`${errors.mail_confirmation ? 'form-know-you-item-input-small input-error' : 'form-know-you-item-input-small'}`}
                           placeholder={intl.formatMessage({id: "placeholder_mail_confirmation"})}/>
                </div>
            </div>
        </div>

    )
}